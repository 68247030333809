<template>
  <div>
    <validation-observer ref="simpleRules">
      <b-card-text>
        <b-alert
          v-if="dataLine.interview_number"
          variant="primary"
          show
        >
          <div class="alert-body text-center">
            <div>
              <FeatherIcon
                icon="InfoIcon"
                size="36"
              />
              <br>
              Yenileme için görüşme notu oluşturulmuş.
            </div>
            <b-button
              :to="'/interviews/view/' + dataLine.interview_number"
              class="mt-1"
              variant="primary"
            >
              Görüntüle
            </b-button>
          </div>
        </b-alert>
        <b-row v-else>
          <b-col
            cols="12"
            md="3"
          >
            <insurance :data-line="dataLine" />
          </b-col>
          <b-col
            cols="12"
            md="9"
          >
            <b-row>
              <b-col cols="12">
                <customer-search />
              </b-col>
            </b-row>
            <b-row v-if="interviewLine.newCustomer === true || interviewLine.id_com_customer !== null">
              <b-col cols="12">
                <company-name />
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <tckn />
              </b-col>
              <b-col
                cols="12"
                md="6"
              >
                <name-surname />
              </b-col>
              <b-col
                cols="12"
                md="12"
              >
                <phone />
              </b-col>
              <template v-if="!getPhoneControl.result">
                <b-col
                  v-if="interviewLine.customer_kvkk !== '1'"
                  cols="12"
                >
                  <template v-if="interviewLine.id_com_customer">
                    <kvkk-inline
                      :customer-id="interviewLine.id_com_customer"
                      :refresh-data="refreshData"
                      :card-status="false"
                    />
                  </template>
                  <template v-else>
                    <kvkk />
                  </template>
                  <b-alert
                    variant="danger"
                    show
                  >
                    <div class="alert-body text-center">
                      KVKK Onayından sonra görüşme notu oluşturabilirsiniz.
                    </div>
                  </b-alert>
                </b-col>
                <template v-if="interviewLine.customer_kvkk === '1'">
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <meet />
                  </b-col>
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <interview-subjects />
                  </b-col>
                  <b-col
                    cols="12"
                    md="4"
                  >
                    <interview-statuses />
                  </b-col>
                  <!--                  <b-col cols="12">-->
                  <!--                    <date-time />-->
                  <!--                  </b-col>-->
                  <b-col cols="12">
                    <interview-content />
                  </b-col>
                  <b-col cols="12">
                    <div
                      v-if="interviewLine.customer_kvkk === '1'"
                      class="text-right"
                    >
                      <b-button
                        variant="success"
                        :disabled="getPhoneControl.result"
                        @click="submitInterview"
                      >
                        Kaydet
                      </b-button>
                    </div>
                  </b-col>
                </template>
              </template>
            </b-row>
          </b-col>
        </b-row>
      </b-card-text>
    </validation-observer>
  </div>
</template>

<script>
import { ValidationObserver, localize } from 'vee-validate'
import {
  BRow, BCol, BButton, BCardText, BAlert,
} from 'bootstrap-vue'
import Insurance from '@/views/Insurance/Renewal/Index/elements/Insurance.vue'
import CustomerSearch from '@/views/Insurance/Renewal/Index/elements/CustomerSearch.vue'
import CompanyName from '@/views/Insurance/Renewal/Index/elements/CompanyName.vue'
import Tckn from '@/views/Insurance/Renewal/Index/elements/Tckn.vue'
import NameSurname from '@/views/Insurance/Renewal/Index/elements/NameSurname.vue'
import Phone from '@/views/Insurance/Renewal/Index/elements/Phone.vue'
import Meet from '@/views/Insurance/Renewal/Index/elements/Meet.vue'
import InterviewSubjects from '@/views/Insurance/Renewal/Index/elements/InterviewSubjects.vue'
import InterviewStatuses from '@/views/Insurance/Renewal/Index/elements/InterviewStatuses.vue'
// import DateTime from '@/views/Insurance/Renewal/Index/elements/DateTime.vue'
import InterviewContent from '@/views/Insurance/Renewal/Index/elements/Content.vue'
import Kvkk from '@/views/Insurance/Renewal/Index/elements/Kvkk.vue'
import KvkkInline from '@/layouts/components/common/KvkkInline.vue'

export default {
  name: 'ModalCard',
  components: {
    KvkkInline,
    Insurance,
    CustomerSearch,
    CompanyName,
    Tckn,
    NameSurname,
    Phone,
    Meet,
    InterviewSubjects,
    InterviewStatuses,
    // DateTime,
    InterviewContent,
    Kvkk,
    ValidationObserver,
    BRow,
    BCol,
    BButton,
    BCardText,
    BAlert,
  },
  computed: {
    interviewLine() {
      return this.$store.getters['insuranceRenewals/interviewLine']
    },
    filterData() {
      return this.$store.getters['insuranceRenewals/dataFilter']
    },
    userList() {
      return this.$store.getters['insuranceRenewals/userList']
    },
    periodList() {
      return this.$store.getters['insuranceRenewalPeriods/dataList']
    },
    dataLine() {
      return this.$store.getters['insuranceRenewals/dataItem']
    },
    getPhoneControl() {
      return this.$store.getters['customers/getPhoneControl']
    },
  },
  created() {
    localize('tr')
  },
  methods: {
    refreshData() {

    },
    submitInterview() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.$store.dispatch('insuranceRenewals/saveInterview', this.interviewLine)
        }
      })
    },
    getUsers() {
      this.filterData.id_com_user = null
      this.$store.dispatch('insuranceRenewals/getUserList', this.filterData.id_com_insurance_renewal_periods)
      this.getDataList()
    },
    getDataList() {
      const where = {
        'com_insurance_renewal.id_com_insurance_renewal_periods': this.filterData.id_com_insurance_renewal_periods,
      }
      if (this.filterData.id_com_user) {
        where['com_insurance_renewal.id_com_user'] = this.filterData.id_com_user
      }
      if (this.filterData.id_com_insurance_renewal_periods) {
        this.$store.dispatch('insuranceRenewals/getDataList', {
          where,
          limit: 50,
          start: 0,
        })
      }
    },
  },
}
</script>
