<template>
  <div>
    <filter-card :filter-action="getDataList" />
    <b-card
      v-if="dataList.length > 0"
      no-body
    >
      <b-card-header>
        <div><b-card-title>{{ periodName }}</b-card-title></div>
        <div>
          <b-form-checkbox
            v-model="filterData.completeStatus"
            name="check-button"
            switch
            inline
            @change="getDataList"
          >
            Tamamlananları Göster
          </b-form-checkbox>
        </div>
      </b-card-header>
      <b-table
        :fields="fields"
        :items="dataList"
        responsive="sm"
        striped
        small
      >
        <template #cell(username)="data">
          <b-link
            @click="getDataLine(data.item.id)"
          >
            {{ data.item.username }}
          </b-link>
        </template>
        <template #cell(musteri)="data">
          <div class="width-200 overflow-scroll">
            {{ data.item.musteri }}
          </div>
          <div class="text-warning">
            {{ data.item.plaka }}
          </div>
          <div
            v-if="data.item.interview_status"
            class="text-danger"
          >
            {{ data.item.interview_status }}
          </div>
        </template>
        <template #cell(police_no)="data">
          <div>
            <strong>Poliçe No: </strong> {{ data.item.police_no }}
          </div>
          <div class="text-primary">
            <strong>Şirket: </strong> {{ data.item.sirket }}
          </div>
          <div class="text-warning">
            <strong>Branş: </strong> {{ data.item.brans }}
          </div>
          <div
            v-if="data.item.brand"
            class="text-danger"
          >
            <strong>Marka: </strong> {{ data.item.brand }}
          </div>
        </template>
        <template #cell(bitis_tarihi)="data">
          {{ moment(data.item.bitis_tarihi).format('DD.MM.YYYY') }}
        </template>
        <template #cell(gecen_yil_prim)="data">
          {{ data.item.gecen_yil_prim | toCurrency }} ₺
        </template>
      </b-table>
      <b-card-footer>
        <b-pagination
          v-model="currentPage"
          :total-rows="dataCount"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
        >
          <template #prev-text>
            <feather-icon
              icon="ChevronLeftIcon"
              size="18"
            />
          </template>
          <template #next-text>
            <feather-icon
              icon="ChevronRightIcon"
              size="18"
            />
          </template>
        </b-pagination>
      </b-card-footer>
    </b-card>
    <b-modal
      v-model="showModal"
      title="Yenileme Kaydı Oluştur"
      ok-only
      centered
      size="xl"
      hide-footer
      no-close-on-backdrop
    >
      <modal-card />
    </b-modal>
  </div>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardFooter,
  BTable,
  BPagination,
  BLink,
  BFormCheckbox,
} from 'bootstrap-vue'
import FilterCard from '@/views/Insurance/Renewal/Index/FilterCard.vue'
import ModalCard from '@/views/Insurance/Renewal/Index/ModalCard.vue'

export default {
  name: 'Index',
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardFooter,
    BTable,
    BPagination,
    BLink,
    BFormCheckbox,
    FilterCard,
    ModalCard,
  },
  data() {
    return {
      showModal: false,
      currentPage: 1,
      perPage: 50,
      limit: 50,
      start: 0,
      fields: [
        {
          key: 'username',
          label: 'Temsilci',
          thClass: 'font-small-2 table-middle text-nowrap',
          tdClass: 'font-small-2 text-nowrap',
        },
        {
          key: 'musteri',
          label: 'Müşteri',
          thClass: 'font-small-2 table-middle text-nowrap',
          tdClass: 'font-small-2',
        },
        {
          key: 'police_no',
          label: 'Poliçe',
          thClass: 'font-small-2 table-middle text-nowrap',
          tdClass: 'font-small-2 text-nowrap',
        },
        {
          key: 'gecen_yil_prim',
          label: 'GEÇEN YIL PRİM',
          thClass: 'font-small-2 table-middle text-nowrap',
          tdClass: 'font-small-2 text-nowrap',
        },
        {
          key: 'bitis_tarihi',
          label: 'BİTİŞ TARİHİ',
          thClass: 'font-small-2 table-middle text-nowrap',
          tdClass: 'font-small-2 text-nowrap',
        },
      ],
    }
  },
  computed: {
    periodName() {
      return this.periodList.find(e => e.id === this.filterData.id_com_insurance_renewal_periods).title
    },
    filterData() {
      return this.$store.getters['insuranceRenewals/dataFilter']
    },
    dataCount() {
      return this.$store.getters['insuranceRenewals/dataCounts']
    },
    dataList() {
      return this.$store.getters['insuranceRenewals/dataList']
    },
    dataLine() {
      return this.$store.getters['insuranceRenewals/dataItem']
    },
    saveStatus() {
      return this.$store.getters['insuranceRenewals/dataSaveStatus']
    },
    periodList() {
      return this.$store.getters['insuranceRenewalPeriods/dataList']
    },
  },
  watch: {
    currentPage(val) {
      this.start = (val - 1) * 50
      this.getDataList()
    },
    saveStatus(val) {
      if (val.status === true) {
        const config = {
          icon: 'success',
          title: 'İşlem Başarılı!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      } else if (val.status === false) {
        const config = {
          icon: 'error',
          title: 'İşlem Hatası!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      }
    },
  },
  created() {
    this.getPeriods()
  },
  methods: {
    getPeriods() {
      this.$store.dispatch('insuranceRenewalPeriods/getDataList', {
        select: [
          'com_insurance_renewal_periods.id AS id',
          'com_insurance_renewal_periods.title AS title',
        ],
        order_by: ['com_insurance_renewal_periods.id', 'DESC'],
        limit: 4,
        start: 0,
      })
    },
    getDataList() {
      const orWhere = {}
      const where = {
        'com_insurance_renewal.id_com_insurance_renewal_periods': this.filterData.id_com_insurance_renewal_periods,
      }
      if (this.filterData.id_com_user) {
        where['com_insurance_renewal.id_com_user'] = this.filterData.id_com_user
      }
      if (this.filterData.completeStatus === false) {
        orWhere['com_interview_status.status'] = '0'
        orWhere['com_insurance_renewal.id_com_interview'] = null
      }
      const orLike = {}
      if (this.filterData.search_keyword) {
        orLike['com_insurance_renewal.musteri'] = this.filterData.search_keyword
        orLike['com_insurance_renewal.police_no'] = this.filterData.search_keyword
      }
      if (this.filterData.id_com_insurance_renewal_periods) {
        this.$store.dispatch('insuranceRenewals/getDataList', {
          where,
          or_like: orLike,
          or_where: orWhere,
          order_by: ['com_insurance_renewal.bitis_tarihi,com_insurance_renewal.id,com_insurance_renewal.musteri', 'ASC'],
          limit: this.limit,
          start: this.start,
        })
      }
    },
    getDataLine(id) {
      this.$store.dispatch('insuranceRenewals/getDataItem', id)
        .then(res => {
          if (res) {
            this.showModal = true
            this.$store.commit('insuranceRenewals/RESET_INTERVIEW_LINE', id)
            this.$store.commit('customers/CUSTOMERS_LIST', [])
            this.$store.commit('customers/RESET_KVKK_SMS_RESULT')
            this.$store.commit('customers/RESET_PHONE_CONTROL')
          }
        })
    },
    sweetAlert(config) {
      this.$swal({
        icon: config.icon,
        title: config.title,
        text: config.text,
        html: config.html,
        allowOutsideClick: false,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        confirmButtonText: config.buttonText,
        buttonsStyling: false,
      }).then(resoponse => {
        if (resoponse.isConfirmed) {
          this.showModal = false
        }
      })
    },
  },
}
</script>
